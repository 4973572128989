<template>
  <div>
    <inquiry-list/>
  </div>
</template>

<script>
import InquiryList from '@/components/InquiryList'
export default {
  components: {InquiryList},
  props: [],
  data() {
    return {
    };
  },
  mounted() {

    switch (this.$store.state.currentPermissions) {
      case 1:
        this.$store.state.currentMessage=[0,1];//询单信息显示什么数据
        this.$store.state.currentButton=[0,1];
        break
      case 2:
        this.$store.state.currentMessage=[0,1,2,3,4,5,6,7,8];
        this.$store.state.currentButton=[0,6];
        break
      case 3:
        this.$store.state.currentMessage=[0,1,2,3,6,8];
        this.$store.state.currentButton=[0,1];
        break
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
